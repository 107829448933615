import { useMemo } from 'react'

import {
  useFarwestAverageWeeklySalesbyOrderMode,
  useFarwestEquivalentUnitCount,
  useFarwestTotalSalesPercentbyDeliveryMode,
  useFarwestTotalSalesPercentbyOrderMode,
  useFarwestTotalSalesbyDeliveryMode,
} from '../../table/hooks/useFarwestFlashSalesByOrderMode'

export const fwWingstopFlashSalesByOrderModeConfigs = {
  kpiName: 'string',
  farwestFlashSalesByOrderModeWeek1InPast: 'string',
  farwestFlashSalesByOrderModeWeek2InPast: 'string',
  farwestFlashSalesByOrderModeWeek3InPast: 'string',
  farwestFlashSalesByOrderModeWeek4InPast: 'string',
  farwestFlashSalesByOrderModeWeek5InPast: 'string',
  farwestFlashSalesByOrderModeWeek6InPast: 'string',
  farwestFlashSalesByOrderModeWeek7InPast: 'string',
  farwestFlashSalesByOrderModeWeek8InPast: 'string',
}

const titleData = {
  farwestFlashSalesByOrderModeWeek1InPast: '',
  farwestFlashSalesByOrderModeWeek2InPast: '',
  farwestFlashSalesByOrderModeWeek3InPast: '',
  farwestFlashSalesByOrderModeWeek4InPast: '',
  farwestFlashSalesByOrderModeWeek5InPast: '',
  farwestFlashSalesByOrderModeWeek6InPast: '',
  farwestFlashSalesByOrderModeWeek7InPast: '',
  farwestFlashSalesByOrderModeWeek8InPast: '',
}

const useFwWingstopSalesbyOrderMode = () => {
  const { data: salesbyOrderModeData, loading: salesbyOrderModeLoading } =
    useFarwestAverageWeeklySalesbyOrderMode()
  const { data: salesbyDeliveryModeData, loading: salesbyDeliveryModeLoading } =
    useFarwestTotalSalesbyDeliveryMode()
  const {
    data: salesPercentbyOrderModeData,
    loading: salesPercentbyOrderModeLoading,
  } = useFarwestTotalSalesPercentbyOrderMode()
  const {
    data: salesPercentbyDeliveryModeData,
    loading: salesPercentbyDeliveryModeLoading,
  } = useFarwestTotalSalesPercentbyDeliveryMode()
  const { data: equivalentUnitCountData, loading: equivalentUnitCountLoading } =
    useFarwestEquivalentUnitCount()

  const loading =
    salesbyOrderModeLoading ||
    salesbyDeliveryModeLoading ||
    salesPercentbyOrderModeLoading ||
    salesPercentbyDeliveryModeLoading ||
    equivalentUnitCountLoading

  return useMemo(() => {
    const source = [
      { kpiName: 'Average Weekly Sales by Order Mode', ...titleData },
      ...(salesbyOrderModeData?.source?.map((d) => ({
        kpiName: d.orderPriceName,
        farwestFlashSalesByOrderModeWeek1InPast:
          ((d.orderPriceWeek1InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          ((d.orderPriceWeek2InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          ((d.orderPriceWeek3InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          ((d.orderPriceWeek4InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          ((d.orderPriceWeek5InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          ((d.orderPriceWeek6InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          ((d.orderPriceWeek7InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          ((d.orderPriceWeek8InPast as number) / 100)?.toFixed(2) || '-',
      })) || []),
      { kpiName: '', ...titleData },
      { kpiName: 'Total Sales by Delivery Mode', ...titleData },
      ...(salesbyDeliveryModeData?.source?.map((d) => ({
        kpiName: d.deliveryPriceName,
        farwestFlashSalesByOrderModeWeek1InPast:
          ((d.deliveryPriceWeek1InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          ((d.deliveryPriceWeek2InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          ((d.deliveryPriceWeek3InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          ((d.deliveryPriceWeek4InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          ((d.deliveryPriceWeek5InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          ((d.deliveryPriceWeek6InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          ((d.deliveryPriceWeek7InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          ((d.deliveryPriceWeek8InPast as number) / 100)?.toFixed(2) || '-',
      })) || []),
      { kpiName: '', ...titleData },
      { kpiName: 'Total Sales Percent by Order Mode', ...titleData },
      ...(salesPercentbyOrderModeData?.source?.map((d) => ({
        kpiName: d.orderPercentName,
        farwestFlashSalesByOrderModeWeek1InPast:
          ((d.orderPercentWeek1InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          ((d.orderPercentWeek2InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          ((d.orderPercentWeek3InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          ((d.orderPercentWeek4InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          ((d.orderPercentWeek5InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          ((d.orderPercentWeek6InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          ((d.orderPercentWeek7InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          ((d.orderPercentWeek8InPast as number) / 100)?.toFixed(4) || '-',
      })) || []),
      { kpiName: '', ...titleData },
      { kpiName: 'Total Sales Percent by Delivery Mode', ...titleData },
      ...(salesPercentbyDeliveryModeData?.source?.map((d) => ({
        kpiName: d.deliveryPercentName,
        farwestFlashSalesByOrderModeWeek1InPast:
          ((d.deliveryPercentWeek1InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          ((d.deliveryPercentWeek2InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          ((d.deliveryPercentWeek3InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          ((d.deliveryPercentWeek4InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          ((d.deliveryPercentWeek5InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          ((d.deliveryPercentWeek6InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          ((d.deliveryPercentWeek7InPast as number) / 100)?.toFixed(4) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          ((d.deliveryPercentWeek8InPast as number) / 100)?.toFixed(4) || '-',
      })) || []),
      { kpiName: '', ...titleData },
      ...(equivalentUnitCountData?.source?.map((d) => ({
        kpiName: d.equivalentUnitCountName,
        farwestFlashSalesByOrderModeWeek1InPast:
          (d.equivalentUnitCountWeek1InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          (d.equivalentUnitCountWeek2InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          (d.equivalentUnitCountWeek3InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          (d.equivalentUnitCountWeek4InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          (d.equivalentUnitCountWeek5InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          (d.equivalentUnitCountWeek6InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          (d.equivalentUnitCountWeek7InPast as number)?.toFixed(0) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          (d.equivalentUnitCountWeek8InPast as number)?.toFixed(0) || '-',
      })) || []),
    ]

    return {
      data: {
        source,
      },
      loading,
    }
  }, [
    loading,
    salesbyOrderModeData,
    salesbyDeliveryModeData,
    salesPercentbyOrderModeData,
    salesPercentbyDeliveryModeData,
    equivalentUnitCountData,
  ])
}

export default useFwWingstopSalesbyOrderMode
