import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'

type IDataType = Record<
  'listLocationGroupMetricValues',
  {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
>

export interface MetricResult {
  metricCode: string
  value: number
  unit: string | null
  loading: boolean
}

const query = gql`
  query listLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        metricData
      }
    }
  }
`

const useFetchGroupMetricValue = (
  metrics: string[],
  startDate: string | undefined,
  endDate: string | undefined,
  // FIXME should remove
  locationGroupId: number,
  skip: boolean,
): { results: MetricResult[]; isLoading: boolean } => {
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iLocationGroupIds: groupFilter?.ids,
      iMetricCodes: metrics,
    },
    skip: !startDate || !endDate || !groupFilter?.ids || skip,
  })

  const results = useMemo(() => {
    if (!data) return []

    return metrics.reduce((acc, metricCode) => {
      let value = data?.listLocationGroupMetricValues?.nodes
        .map((m) => m.metricData[metricCode]?.value)
        .filter(Boolean)
        .reduce((result, num) => result + num, 0)
      const unit =
        data?.listLocationGroupMetricValues?.nodes[0]?.metricData?.[metricCode]
          ?.unit || null

      if (unit === 'CENT') {
        value *= 0.01
      } else if (unit === 'PERCENTAGE') {
        value *= 100
      }

      acc.push({ metricCode, value, unit, loading })
      return acc
    }, [] as MetricResult[])
  }, [data, metrics, loading])

  return { results, isLoading: loading }
}

export { useFetchGroupMetricValue }
